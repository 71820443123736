import loadable from '@loadable/component';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import animations from 'src/assets/styles/animations';
import { APP } from 'src/resources/constants/app';
import { URL } from 'src/resources/constants/url';
import detectWindow from 'src/screens/app/components/Layout/styles.scss';
import BrowserDetect from 'src/services/browser-detect';
import localStore from 'src/services/localStore';
import BlockSectionHeader from 'src/components/blocksection/header';

import styles from './styles.scss';

const Container = loadable(() => import('./container'));
const Header = loadable(() => import('./header'));
const MobileHeader = loadable(() => import('src/components/mobileHeader'));
const Footer = loadable(() => import('./footer'));
const FooterIntro = loadable(() => import('./footer/content'));

class ScreenContainer extends PureComponent {
  renderHeader = () => {
    const { header, bgHeader, mobileHeader } = this.props;
    const isApp = localStore.get('LOAD_WEBVIEW') || false;
    if (BrowserDetect.isMobile) {
      if (isApp || mobileHeader === false) return null;
      return <MobileHeader />;
    }
    return (
      <div
        className={cx(
          detectWindow.desktop,
          bgHeader ? styles.bgHeader : styles.header,
        )}
      >
        <Header
          {...(typeof header === 'object' ? header : {})}
          bgHeader={bgHeader}
        />
      </div>
    );
  };

  renderBodyWithWrapper = () => {
    const { children } = this.props;
    return (
      <Container className={cx(styles.body, animations.fadeIn)} fluid>
        {children}
      </Container>
    );
  };

  renderFooter = () => {
    const { footer, mobileFooter } = this.props;
    if (BrowserDetect.isMobile && mobileFooter === false) return null;
    return <Footer {...(typeof footer === 'object' ? footer : {})} />;
  };

  renderFooterInto = () => {
    return <BlockSectionHeader className={styles.footer}><FooterIntro /></BlockSectionHeader>;
  };

  componentDidMount() {
    const path = window.location.href;
    if (!path.includes(URL.USER_SIGN_UP)) {
      localStore.save(APP.SOURCE, window.location.href);
    }
  }

  render() {
    const { children, header, bodyWrapper, footer, noContainer, footerIntro } = this.props;
    const isApp = localStore.get('LOAD_WEBVIEW') || false;
    if (noContainer) {
      return children;
    }
    return (
      <React.Fragment>
        {header && this.renderHeader()}
        {bodyWrapper ? this.renderBodyWithWrapper() : children}
        {footer && !isApp && this.renderFooter()}
        {footerIntro && !isApp && this.renderFooterInto()}
      </React.Fragment>
    );
  }
}

ScreenContainer.defaultProps = {
  footer: true,
  header: true,
  bodyWrapper: true,
  noContainer: false,
};

ScreenContainer.propTypes = {
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  bodyWrapper: PropTypes.bool,
  noContainer: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default withRouter(ScreenContainer);
